'use client';
import useProgressiveImg from '@/components/hooks/useProgressiveImg';

const ExpensiveImage: React.FC<any> = ({
  images,
  width,
  height,
  className,
  style,
  src,
  ...otherProps
}) => {
  const [currentSrc, { blur }] = useProgressiveImg(...images);

  return (
    <img
      width={width}
      height={height}
      style={{ filter: blur && !src ? 'blur(1px)' : 'none', ...style }}
      src={currentSrc}
      alt=''
      className={className}
      {...otherProps}
    />
  );
};

export default ExpensiveImage;
