/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-empty-function */
'use client';

import clsx from 'clsx';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useParams } from 'next/navigation';
import React from 'react';
import CountUp from 'react-countup';
import { Autoplay, Pagination } from 'swiper/modules';

import Link from '@/components/core/Link';
import Swiper from '@/components/core/Swiper';
import GLEffect from '@/components/core/Swiper/components/Modules/GLEffect';
import { GLPlugin } from '@/components/core/Swiper/components/Modules/GLPlugin';
import useIsMobile from '@/components/hooks/useScreenSize';

import {
  promotionRouteEnum,
  promotionsPage,
} from '@/app/[lng]/(general)/promotion/[...slug]/components/PromotionsPage';
import i18next, { useTranslation } from '@/app/i18n/client';

const Marquee = dynamic(() => import('react-fast-marquee'), {
  ssr: false,
  loading: () => {
    const text = i18next.t('topSlider.marqueeContent', {
      ns: 'HomePage',
    });
    return (
      <span className='hide-scroll-bar absolute bottom-[4px] flex max-h-[24px] items-center overflow-x-hidden pl-1'>
        {`${text}`}
      </span>
    );
  },
} as any);

const slidesData = [
  {
    img: '/img/banner/banner_mb_1.png',
    link: promotionsPage[promotionRouteEnum.CASHBACK_WEEKLY].link,
    onClick: null,
  },
  {
    img: '/img/banner/banner_mb_2.png',
    link: promotionsPage[promotionRouteEnum.FIRST_DEPOSIT].link,
    onClick: null,
  },
  {
    img: '/img/banner/banner_mb_3.png',
    link: '/game/sports',
    onClick: null,
  },
];
const slidesMobileData = [
  {
    img: '/img/banner/banner_mb_1.png',
    link: promotionsPage[promotionRouteEnum.CASHBACK_WEEKLY].link,
    onClick: null,
  },
  {
    img: '/img/banner/banner_mb_2.png',
    link: promotionsPage[promotionRouteEnum.FIRST_DEPOSIT].link,
    onClick: null,
  },
  {
    img: '/img/banner/banner_mb_3.png',
    link: '/?slug=sports',
    onClick: () => {
      window.homePage?.setGamePage('sports');
      window.homePage?.setCateDetail('all');
      window.scrollTo(0, 0);
    },
  },
];

interface ITopSlider {
  standClass?: string;
  marqueeContainerClass?: string;
  jackpot?: any;
}

const TopSlider: React.FC<ITopSlider> = ({
  standClass = '',
  marqueeContainerClass = '',
  jackpot,
}) => {
  const { lng } = useParams();
  const { t } = useTranslation(lng as string, 'HomePage');
  const isMobile = useIsMobile();
  return (
    <div className='vw:h-[100px] relative w-full overflow-auto md:!h-[335px] md:overflow-y-hidden '>
      <Swiper
        className='!vw:h-[100px] w-full bg-black md:!h-[335px] md:!h-auto'
        options={{
          wrapperClass: 'md:!h-[335px] vw:!h-[100px] w-full',
          updateOnWindowResize: true,
          watchOverflow: true,
          observer: true,
          observeParents: true,
          grabCursor: true,
          modules: [Autoplay, Pagination, GLPlugin, GLEffect],
          initialSlide: 0,
          breakpoints: {
            768: {
              pagination: {
                enabled: true,
                horizontalClass: 'topSliderHorizontalGiftCode',
                bulletActiveClass: 'topSliderBulletActive',
                bulletClass: 'topSliderBullet',
                clickable: true,
              },
            },
          },
          pagination: {
            enabled: false,
          },
          slidesPerView: 1,
          autoplay: {
            delay: 4000,
          },
          speed: 600,
          loop: true,
          effect: 'gl',
          // @ts-ignore
          gl: {
            shader: 'morph-x',
          },
        }}
        slidesData={isMobile ? slidesMobileData : slidesData}
        slideItemClass='md:!h-full !h-full !m-auto'
      >
        {(slideItem: (typeof slidesData)[0], index: number) => (
          <Link
            href={slideItem.link}
            className='vw:h-[100px] relative flex justify-center md:!w-auto md:p-0 '
            key={index}
            aria-label={slideItem.link}
            onClick={
              typeof slideItem?.onClick === 'function'
                ? slideItem?.onClick
                : () => {}
            }
          >
            {/* <div
                className='pointer-events-none absolute inset-0 bottom-0 left-0 right-0 top-0 z-[-100] hidden h-full w-full bg-[url("/img/banner/background.png")] md:block'
                // src='/img/banner/background.png'
                // alt=''
                width={1581}
                height={335}
              /> */}
            {/* <div className='md:before:bg-gradient-left md:after:bg-gradient-right relative w-full md:!w-auto md:before:absolute md:before:right-0 md:before:top-0 md:before:z-[40] md:before:h-full md:before:w-[40px] md:before:bg-[100%_100%] md:before:content-[""] md:after:absolute md:after:left-0 md:after:top-0 md:after:z-[40] md:after:h-full  md:after:w-[40px] md:after:bg-[100%_100%] md:after:content-[""]'> */}
            <Image
              priority={index === 0}
              className={clsx(
                'z-1 md:container-pc vw:h-[100px] relative !h-full h-full w-full rounded-xl object-cover md:!h-[335px] md:!w-auto md:rounded-none',
                isMobile ? 'swiper-gl-image' : 'swiper-gl-image'
              )}
              width={1148}
              height={335}
              src={slideItem.img}
              alt=''
            />
            {/* </div> */}
          </Link>
        )}
      </Swiper>
      <div className='absolute bottom-0 left-0 z-10 hidden h-auto w-full text-clip md:block'>
        <div
          className={clsx(
            '!bg-linear-1 flex min-h-[35px] items-center overflow-hidden pl-[120px]',
            marqueeContainerClass
          )}
        >
          <svg
            className='shrink-0'
            fill='none'
            height='13'
            viewBox='0 0 12 13'
            width='12'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              clipRule='evenodd'
              d='M7.90482 7.69636C8.02188 7.6078 8.12602 7.502 8.21336 7.38179C8.39955 7.12552 8.49983 6.81688 8.49983 6.50011C8.49983 6.18334 8.39955 5.87471 8.21336 5.61844C8.12597 5.49816 8.02177 5.39231 7.90462 5.30372C7.87613 5.28217 7.84878 5.25878 7.82294 5.23382C7.79925 5.21093 7.77684 5.18673 7.75597 5.16141C7.62365 5.00087 7.5537 4.7955 7.61787 4.598C7.7032 4.33537 7.98891 4.18754 8.22935 4.32335C8.3262 4.37806 8.41923 4.43913 8.50781 4.50612M8.50781 4.50612C8.70298 4.65374 8.87676 4.83023 9.02238 5.03065C9.3327 5.45777 9.49983 5.97217 9.49983 6.50011C9.49983 7.02806 9.3327 7.54246 9.02238 7.96958C8.87673 8.17004 8.70304 8.34647 8.50781 8.4941C8.41923 8.5611 8.3262 8.62217 8.22935 8.67688C7.98891 8.81269 7.7032 8.66485 7.61787 8.40223C7.5537 8.20472 7.62365 7.99936 7.75597 7.83881C7.77684 7.81349 7.79925 7.78929 7.82294 7.76641C7.84878 7.74145 7.87632 7.71791 7.90482 7.69636'
              fill='#373737'
              fillRule='evenodd'
            />
            <path
              clipRule='evenodd'
              d='M8.94378 9.12979C9.32939 8.91808 9.66583 8.62298 9.92708 8.26339C10.2995 7.75084 10.5 7.13357 10.5 6.50003C10.5 5.86649 10.2995 5.24922 9.92708 4.73668C9.65321 4.35972 9.29673 4.05364 8.88758 3.8402C8.88694 3.83986 8.8863 3.83953 8.88567 3.8392C8.86794 3.82987 8.85073 3.81975 8.8341 3.80891C8.81603 3.79713 8.79864 3.7845 8.78198 3.77109C8.60314 3.62709 8.5094 3.39353 8.58159 3.17133C8.66693 2.90871 8.95083 2.76219 9.20076 2.8796C9.2511 2.90324 9.30088 2.92791 9.3501 2.95359C9.89561 3.23816 10.3709 3.64626 10.7361 4.14889C11.2326 4.83228 11.5 5.65531 11.5 6.50003C11.5 7.34475 11.2326 8.16778 10.7361 8.85117C10.3709 9.3538 9.89561 9.7619 9.3501 10.0465C9.30088 10.0722 9.2511 10.0968 9.20076 10.1205C8.95083 10.2379 8.66693 10.0914 8.58159 9.82873C8.5094 9.60654 8.60314 9.37297 8.78198 9.22897C8.79864 9.21556 8.81603 9.20293 8.8341 9.19115C8.85073 9.18031 8.86794 9.17019 8.88567 9.16087C8.8863 9.16053 8.88694 9.1602 8.88758 9.15987C8.90643 9.15004 8.92516 9.14001 8.94378 9.12979Z'
              fill='#373737'
              fillRule='evenodd'
            />
            <path
              d='M7 10.2963V2.70385C7 1.84469 5.98808 1.38551 5.3415 1.95127L3 4.00008H2C1.17157 4.00008 0.5 4.67166 0.5 5.50008V7.50008C0.5 8.32851 1.17157 9.00008 2 9.00008H3L5.3415 11.0489C5.98808 11.6147 7 11.1555 7 10.2963Z'
              fill='#373737'
            />
          </svg>
          <span className='ml-[10px] text-base text-[#292929]'>
            <Marquee
              loop={500}
              speed={50}
              autoFill
              className='hide-scroll-bar hidden !overflow-x-hidden'
            >
              <span className='hide-scroll-bar !overflow-x-hidden pr-10'>
                {t('topSlider.marqueeContent')}
              </span>
            </Marquee>
          </span>
        </div>
        <div
          className={clsx(
            'absolute bottom-[-1px] right-0 z-10 ml-auto flex  h-[63px] min-w-[780px] items-center overflow-hidden',
            standClass
          )}
        >
          <div className='absolute z-50 ml-[66px] flex h-[63px] items-center text-xl font-bold text-white'>
            {t('topSlider.totalText')}
            <span
              className='bg-linear-2 relative ml-[19px] h-[35px] !bg-clip-text	!bg-[100%_100%] py-1 text-[40px] text-[#ECCB77]'
              style={
                {
                  textShadow: '2px 2px 0px #7B4801',
                } as React.CSSProperties
              }
            >
              <CountUp delay={2} start={jackpot - jackpot / 3} end={jackpot} />{' '}
              {'\t'}฿
              <span
                style={
                  {
                    WebkitTextStroke: '2px transparent',
                  } as React.CSSProperties
                }
                className='bg-linear-2 absolute right-0 top-0 z-10 w-full	!bg-clip-text !bg-[100%_100%] py-1 text-[40px] text-[#D6B25C]'
              >
                <CountUp
                  delay={2}
                  start={jackpot - jackpot / 3}
                  end={jackpot}
                />{' '}
                {'\t'}฿
              </span>
            </span>
          </div>
          <Image
            priority
            className='absolute right-0 top-[6px] mr-12 w-[145px]'
            src='/img/banner/golden.png'
            alt='golden'
            width={145}
            height={50}
          />
          <Image
            priority
            className='inset-0 object-cover'
            src='/img/banner/stand_jackpot.svg'
            alt=''
            width={780}
            height={63}
          />
        </div>
      </div>
    </div>
  );
};

export default TopSlider;
