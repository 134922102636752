/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

export function loadImage(
  src?: string,
  onload?: () => void,
  onerror?: () => void
) {
  const image = new Image();
  if (src) image.src = src;
  if (onload) {
    image.onload = onload;
  }
  if (onerror) {
    image.onerror = onerror;
  }
  return image;
}

const useProgressiveImg = (
  low = '',
  medium = '',
  high = '',
  defaultImg = ''
): any => {
  const [currentSrc, setCurrentSrc] = useState<string>('');

  let currentIndex = 3;
  function loadImages() {
    const arr = [high, medium, low];

    arr
      .filter((src) => !!src)
      .forEach((src, index) => {
        loadImage(
          src,
          () => {
            if (index < currentIndex) {
              currentIndex = index;
              setCurrentSrc(src);
            }
          },
          () => {
            setCurrentSrc(defaultImg);
          }
        );
      });
  }

  useEffect(() => {
    // if (!currentSrc) loadImages();
    loadImages();
  }, [low, medium, high]);

  return [
    currentSrc || low,
    { blur: currentSrc === low, loaded: currentSrc === high },
  ];
};

export default useProgressiveImg;
